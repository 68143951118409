@import '../../styles/variables.module';
@import 'src/styles/mixins';
@import 'src/styles/layers';

$animation_duration: 0.25s;

$side-menu-backdrop_z-index: 0;
$side-menu_z-index: $side-menu-backdrop_z-index + 1;

.side-menu {
  z-index: $side-menu_z-index;

  overflow: scroll;
  width: 14rem;
  height: 100%;
  display: grid;
  grid-row-gap: 1.9rem;
  grid-template-rows: auto 1fr;
  flex: none;

  background-color: var(--green);
  color: var(--white);

  transition: left #{$animation_duration}, width #{$animation_duration};

  @include hideScrollbars();

  .logo {
    box-sizing: border-box;
    padding-left: $gap_sm;
  }

  &.is-sliding {
    position: fixed;
    left: -100%;

    width: 14rem;
    height: 100%;

    .side-menu__content__scrollbars {
      padding-bottom: 2.4rem;
    }

    &.is-open {
      left: 0;
    }
  }

  &:not(.is-sliding) {
    .side-menu__close-btn,
    + .side-menu-backdrop {
      display: none;
    }

    &:not(.is-open) {
      width: 5.8rem;

      .side-menu-item__label {
        position: absolute;
        opacity: 0;
        visibility: hidden;
        margin-left: 0;
        width: 0;
      }

      .side-menu__content__scrollbars {
        padding-left: 1.1rem;
        padding-right: 1.1rem;
      }
    }
  }

  &.leader {
    background-color: var(--blue-gradient-from);

    .side-menu__head {
      background-color: var(--blue);
    }
  }
}

.side-menu-backdrop {
  z-index: $side-menu-backdrop_z-index !important;
}

.side-menu__head {
  display: flex;
  justify-content: space-between;
  padding: $gap_lg;
  line-height: 0;
  background-color: var(--green-light);
}

.side-menu__close-btn {
  i {
    font-size: 0.8rem;

    color: var(--white);
  }
}

.side-menu__content__scrollbars {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.9rem 1.4rem 5.4rem 0.9rem;
}

.side-menu-item {
  margin-top: 0.9rem;

  a {
    box-sizing: border-box;
    display: flex;
    padding: 0.7rem 1.1rem;
    border-radius: 0.8rem;

    font-family: 'Poppins', sans-serif;

    background-color: transparent;
    color: var(--white);
    text-decoration: none;
    font-weight: bold;

    transition: background-color .2s;

    &:first-of-type {
      margin-top: 0;
    }

    &.inactive {
      opacity: 0.5;
    }

    &:hover,
    &.active {
      background-color: var(--white);
      color: var(--role-color-light);
      box-shadow: $box-shadow;
    }
  }
}

.side-menu-item__label {
  margin-left: 1rem;

  opacity: 1;
  visibility: visible;

  transition: width #{$animation_duration},
  margin-left #{$animation_duration},
  opacity #{$animation_duration},
  visibility #{$animation_duration};
}

.side-menu-icon {
  font-size: 1.3rem;
  line-height: 1;
}

@include until-tablet {
  .side-menu {
    grid-row-gap: 0.9rem;
  }
}
