@import "src/styles/mixins";

.input-wrapper {
  position: relative;

  overflow: hidden;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid var(--border);

  background: var(--white);

  &.default {
    border: 1px solid var(--border);
    border-radius: $border-radius_md;
  }

  &.chat {
    border: 1px solid var(--border);
    border-radius: 1.55rem;
  }

   &.focus {
    border-color: var(--green);
  }

  &.invalid {
    border-color: var(--text-error);
  }

  &.disabled {
    border-color: var(--gray-opacity);

    background-color: var(--bg-light);
  }

  &.sm {
    padding-left: 0.6rem;
    padding-right: 0.6rem;
  }

  &.lg {
    padding-left: 0.9rem;
    padding-right: 0.9rem;
  }

  &.br-md {
    border-radius: $border-radius_md;
  }

  &.br-extra-lg {
    border-radius: $border-radius_extra-lg;
  }
}
