.btn-bookmark {
  padding: 0.2rem 0.4rem;
  border: none;
  border-top-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;

  font-size: 0.7rem;

  background-color: var(--scheme_color);
  color: var(--white);

  cursor: pointer;

  &:hover {
    background-color: var(--scheme_color-light);
  }
}
