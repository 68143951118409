@import '../../styles/variables.module';

.checkbox {
  position: relative;
  display: flex;
  align-items: center;

  user-select: none;
  cursor: pointer;

  &.green {
    .checkbox__checkmark {
      border: 1px solid var(--white);
    }
  }

  &.blue {
    .checkbox__checkmark {
      border: 1px solid var(--border);
    }
  }

  &:hover {
    .checkbox__checkmark {
      border-color: var(--scheme_color);
    }
  }

  &.disabled {
    pointer-events: none;

    .checkbox__checkmark {
      opacity: 0.5;
    }
  }
}

.checkbox__input {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;

  &:checked {
    ~ .checkbox__checkmark {
      background-color: var(--scheme_color);
      border-color: var(--scheme_color);
    }
  }

  &:not(:checked) {
    ~ .checkbox__checkmark {
      &:after {
        display: none;
      }
    }
  }
}

.checkbox__checkmark {
  flex-shrink: 0; // Prevent shrink when many text to the left/right.
  position: relative;

  // px was set to test [AE-411] https://rollncode.atlassian.net/browse/AE-411?focusedCommentId=45869.
  width: 21px;
  height: 21px;
  border-radius: 50%;

  transition: border-color 0.25s;

  &:after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 8px;
    background-position: 0;
    background-repeat: no-repeat;

    background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.33753 0.659931L3.4338 5.56366L1.62319 3.75305C1.32142 3.41357 0.755604 3.41357 0.453836 3.75305C0.114346 4.09254 0.114346 4.62064 0.453836 4.96013L2.83026 7.33655C2.98114 7.48744 3.20747 7.6006 3.4338 7.6006C3.66012 7.6006 3.84873 7.52516 4.03733 7.33655L9.5446 1.82928C9.88409 1.48979 9.88409 0.961699 9.5446 0.62221C9.20511 0.320442 8.67702 0.320442 8.33753 0.659931Z' fill='white'/%3E%3C/svg%3E%0A");

    transform: translate(-50%, -50%);

    content: "";
  }
}
