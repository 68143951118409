@import "src/styles/variables.module";

.input-search__icon {
  font-size: 1rem;
  height: 1rem;
  line-height: 1;
  padding-left: $gap_tiny;

  color: var(--text-body-light);
}
