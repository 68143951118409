.criteria-item {
  font-size: 0.7rem;

  &.completed {
    color: var(--green);

    .criteria-item__icon {
      top: 0.1rem;
    }
  }

  &:not(.completed) {
    .criteria-item__icon {
      font-size: 0.5rem;

      color: var(--text-error);
    }
  }
}

.criteria-item__icon {
  position: relative;

  padding-right: 0.3rem;
}
