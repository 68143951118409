.criteria-list {
  display: flex;
  flex-direction: column;

  > * {
    margin-top: 0.5rem;
  }

  :first-child {
    margin-top: 0;
  }
}
