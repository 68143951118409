@import "src/styles/variables.module";

$tooltip-margin: 6px;
$tooltip-arrow-size: 6px;
$bg-color: var(--white);

.tooltip__tip {
  z-index: 100;
  position: fixed;
  transform: translate(-50%, #{$tooltip-arrow-size});

  padding: $gap_extra-sm $gap-md;
  border-radius: $border-radius_extra-sm;

  background: $bg-color;
  color: var(--black);

  max-width: 12rem;

  &:before {
    position: absolute;
    left: 50%;

    width: 0;
    height: 0;
    border: $tooltip-arrow-size solid transparent;
    margin-left: calc(#{$tooltip-arrow-size} * -1);

    pointer-events: none;
    content: '';
  }

  &.top {
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.12);

    transform: translate(-50%, calc(-100% - #{$tooltip-arrow-size + $tooltip-margin}));

    &:before {
      top: 95%; // not 100% to prevent tiny line between tooltip and arrow.
      border-top-color: $bg-color;
    }
  }

  &.bottom {
    box-shadow: 0 -5px 12px rgba(0, 0, 0, 0.12);

    transform: translate(-50%, #{$tooltip-arrow-size + $tooltip-margin});

    &:before {
      bottom: 95%; // not 100% to prevent tiny line between tooltip and arrow.
      border-bottom-color: $bg-color;
    }
  }
}
