@import 'src/styles/mixins';

.youtubeLink{
  max-width: 300px;
}
.question-block {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: flex-end;
  opacity: 0.1;
}

.question-item {
  color: var(--gray);
  position: absolute;

  &:nth-child(1) {
    top: 1.5rem;
    right: 1.7rem;
    font-size: 4.9rem;
  }
  &:nth-child(2) {
    top: 4.7rem;
    right: 6.5rem;
  }
  &:nth-child(3) {
    top: 7.9rem;
    right: 1.1rem;
  }
  &:nth-child(4) {
    top: 1.5rem;
    right: 8.5rem;
    font-size: 2.5rem;
  }

  &:nth-child(2), &:nth-child(3) {
    font-size: 2.9rem;
  }
}

.profile__grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 0.9rem;
}

.profile__item {
  position: relative;
  box-sizing: border-box;
  padding: 1.3rem 1.1rem;

  &:nth-child(1) {
    grid-column: 1 / 2 span;
  }

  &:nth-child(2) {
    grid-column: 3 / span;
    min-width: 22rem;
  }

  &:nth-child(3) {
    grid-column: 4 / 7;
  }

  @include grid-elements(4, 5) {
    grid-column: 1 / -1;
  }
}

.slider__title {
  margin-bottom: 1.1rem;
}

.profile-title {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 1.5;
  color: var(--gray);
}

sup {
  cursor: pointer;
}

.profile-text-bold {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 1.5;
  color: var(--text-body);

  @include threeDots;
}

.profile-section {
  position: relative;
  z-index: 1;
}

.profile-section__text {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.3;
  color: var(--text-title);
  margin: 0.6rem 0;
}

.profile-section__interests-count {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 3.2rem;
  line-height: 1.25;
  color: var(--text-body);
}

.profile-section__interests-total {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 1.25;
  color: var(--text-body);
}

.profile-performance__flex-text {
  display: flex;
  align-items: center;
  min-width: 5rem;

  span {
    margin-left: 0.5rem;
  }
}

.profile-performance__row {
  display: grid;
  grid-template-columns: 10fr 1rem minmax(5.5rem, 1fr);
  margin-top: 0.9rem;
  grid-column-gap: 0.5rem;

  i {
    display: flex;
    font-size: 0.9rem;
    color: var(--green);
  }
}

.profile-share {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
  margin-right: 2rem;
}

.profile-share__title {
  font-family: 'Poppins', sans-serif;
  font-size: 1.3rem;
  line-height: 1.4;
  color: var(--text-title);
  margin-bottom: 1.6rem;
}

.profile-share__btn {
  width: 9rem;
}

.courses-item {
  max-width: 11.1rem;
  cursor: pointer;
  margin-right: 1.8rem;
}

.courses-item__icon {
  width: 11.1rem;
  height: 4.4rem;

  img {
    width: 100%;
    height: 100%;
    border-radius: 0.4rem;
  }
}

.courses-item__title {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 0.9rem;
  line-height: 1.5;
  color: var(--text-title);
  margin-top: 0.5rem;
  display: block;

  @include threeDots;
}

.courses-item__text {
  color: var(--gray);
  margin-top: 0.4rem;
  display: flex;
  align-items: center;

  i {
    display: flex;
    color: var(--text-body-light);
    margin-right: 0.4rem;
  }

  span {
    font-size: 0.8rem;
    line-height: 1.4;
  }
}

@include until-desktop-lg {
  .profile__item {
    &:nth-child(1) {
      grid-column: 1 / -1;
    }

    &:nth-child(2) {
      min-width: auto;
      grid-column: 1 / 3 span;
    }

    &:nth-child(3) {
      grid-column: 4 / 7;
    }
  }
}

@include until-desktop {
  .profile__item {
    @include grid-elements(1, 3) {
      grid-column: 1 / -1;
    }
  }
}
