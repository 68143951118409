@import "Row.border-radiuses";
@import "Row.gaps";
@import "Row.paddings";
@import "Row.borders";

.row {
  box-sizing: border-box;

  &.hover {
    cursor: pointer;

    transition: background-color 0.2s;

    &:hover {
      background-color: var(--scheme_color-opacity);
    }
  }

  &.active {
    background-color: var(--scheme_color-opacity);
  }

  &.grid {
    display: grid;
  }

  &.flex {
    display: flex;

    &.v {
      flex-direction: column;
    }
  }

  &.h {
    &.grid {
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));

      &.auto-cols {
        grid-template-columns: repeat(auto-fit, minmax(0, auto));
      }
    }
  }

  &.v-s {
    align-content: flex-start;
  }

  &.v-s-b {
    align-content: space-between;
  }

  &.v-c {
    align-items: center;
  }

  &.v-e {
    align-items: end;
  }

  &.h-c {
    justify-content: center;
  }


  &.h-e {
    justify-content: flex-end;
  }

  &.h-s {
    &.flex {
      justify-items: flex-start;
    }

    &.grid {
      justify-content: flex-start;
    }
  }

  &.h-s-b {
    justify-content: space-between;
  }
}

@include until-tablet {
  .row {
    &.h {
      &.grid {
        // Not ideal: changes horizontal grid blocks to vertical on mobile.
        grid-template-columns: 1fr;
      }
    }
  }
}
