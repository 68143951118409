$header-height: 76px;

$mobile-lg-width: 480px;
$tablet-width: 768px;
$desktop-width: 1024px;
$desktop-lg-width: 1600px;

$border: 1px solid var(--border);

$box-shadow: 0 2px 6px rgba(39, 48, 57, 0.1);

$gap_tiny: 0.25rem; // 4
$gap_extra-sm: 0.45rem; // 8
$gap_sm: 0.55rem; // 10
$gap_md: 0.66rem; // 12
$gap_lg: 0.9rem; // 16
$gap_extra-lg: 1.1rem; // 20
$gap_huge: 1.5rem; // 28
$gap_biggest: 2.2rem; // 40

/*
$border-radius_extra-sm: 0.45rem; // 8
$border-radius_sm: 0.55rem; // 10
$border-radius_md: 0.66rem; // 12
$border-radius_extra-lg: 1.45rem; // 28 (may be postfix if wrong).
 */
// px was set to test [AE-411] https://rollncode.atlassian.net/browse/AE-411?focusedCommentId=45869.
$border-radius_extra-sm: 8px;
$border-radius_sm: 10px;
$border-radius_md: 12px;
$border-radius_lg: 16px;
$border-radius_extra-lg: 28px;

:export {
  desktopWidth: $desktop-width;
  gapExtraSmall: $gap_extra-sm;
  gapLarge: $gap_lg;
}
