@import "src/styles/mixins";

.tab-btn {
  padding-left: 0.1rem;
  padding-right: 0.2rem;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 0.2rem solid transparent;

  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  line-height: 1.5;

  background: none;
  color: var(--text-title);

  cursor: pointer;

  @include threeDots();

  &:hover {
    color: var(--scheme_color-light);
  }

  &.active {
    border-color: var(--scheme_color);

    text-shadow:
            0 0 .5px currentColor,
            0 0 .5px currentColor,
            0 0 .5px currentColor; // Fake bold font weight to keep content size static.

    color: var(--scheme_color);
  }
}
