@import "src/styles/layers";
@import "src/styles/mixins";
@import "src/styles/variables.module";

$modal-scrollbars_z-index: 1;

.modal__container {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &.modal-enter {
    .modal__scrollbars {
      opacity: 0;
      transform: scale(0.7);
    }
  }
  &.modal-enter-active {
    .modal__scrollbars {
      opacity: 1;
      transform: translateX(0);
      transition: opacity 300ms, transform 300ms;
    }
  }
  &.modal-exit {
    .modal__scrollbars {
      opacity: 1;
    }
  }
  &.modal-exit-active {
    .modal__scrollbars {
      opacity: 0;
      transform: scale(0.7);
      transition: opacity 300ms, transform 300ms;
    }
  }

  &.loading {
    cursor: wait;
  }
}

.modal_backdrop {
  z-index: #{$modal-scrollbars_z-index - 1} !important;
}

.modal__scrollbars {
  z-index: $modal-scrollbars_z-index;
  position: relative;

  min-width: 18rem;
  max-width: 98%;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;

  background-color: var(--white);
  box-shadow: 5px 10px 20px rgb(0 0 0 / 15%);
}

.modal__inner {
  box-sizing: border-box;
  min-height: 100%;
  display: grid;
  padding: $gap_huge $gap_extra-lg;

  > * {
    margin-top: $gap_lg;
  }

  .modal__close-btn,
  .modal__close-btn + * {
    min-width: 0; // prevent content width overflow
    margin-top: 0;
  }
}

.modal__content {
  font-size: 0.8rem;
  line-height: 1.75;

  color: var(--text-title);
}

.modal__close-btn {
  z-index: 1;
  position: absolute;
  top: 0.3rem;
  right: 0.7rem;

  i {
    font-size: 0.8rem;
  }
}
