.icon-divider {
  position: relative;

  padding-left: 0.3rem;
  padding-right: 0.3rem;

  &:before {
    position: absolute;
    left: 50%;
    top: 50%;

    width: 1px;
    height: 20px;

    background-color: var(--border);

    transform: translate(-50%, -50%);
    content: '';
  }
}
