@import "src/styles/mixins";
@import "src/styles/variables.module";

.row {
  &.ph-tiny {
    padding-left: $gap_tiny;
    padding-right: $gap_tiny;
  }

  &.ph-extra-sm {
    padding-left: $gap_extra-sm;
    padding-right: $gap_extra-sm;
  }

  &.ph-sm {
    padding-left: $gap_sm;
    padding-right: $gap_sm;
  }

  &.ph-md {
    padding-left: $gap_md;
    padding-right: $gap_md;
  }

  &.ph-lg {
    padding-left: $gap_lg;
    padding-right: $gap_lg;
  }

  &.ph-extra-lg {
    padding-left: $gap_extra-lg;
    padding-right: $gap_extra-lg;
  }

  &.ph-huge {
    padding-left: $gap_huge;
    padding-right: $gap_huge;
  }

  &.ph-biggest {
    padding-left: $gap_biggest;
    padding-right: $gap_biggest;
  }

  &.pv-tiny {
    padding-top: $gap_tiny;
    padding-bottom: $gap_tiny;
  }

  &.pv-extra-sm {
    padding-top: $gap_extra-sm;
    padding-bottom: $gap_extra-sm;
  }

  &.pv-sm {
    padding-top: $gap_sm;
    padding-bottom: $gap_sm;
  }

  &.pv-md {
    padding-top: $gap_md;
    padding-bottom: $gap_md;
  }

  &.pv-lg {
    padding-top: $gap_lg;
    padding-bottom: $gap_lg;
  }

  &.pv-extra-lg {
    padding-top: $gap_extra-lg;
    padding-bottom: $gap_extra-lg;
  }

  &.pv-huge {
    padding-top: $gap_huge;
    padding-bottom: $gap_huge;
  }

  &.pv-biggest {
    padding-top: $gap_biggest;
    padding-bottom: $gap_biggest;
  }
}

@include until-desktop {
  .row {
    &.ph-tiny {
      padding-left: $gap_tiny;
      padding-right: $gap_tiny;
    }

    &.ph-extra-sm {
      padding-left: $gap_tiny;
      padding-right: $gap_tiny;
    }

    &.ph-sm {
      padding-left: $gap_extra-sm;
      padding-right: $gap_extra-sm;
    }

    &.ph-md {
      padding-left: $gap_sm;
      padding-right: $gap_sm;
    }

    &.ph-lg {
      padding-left: $gap_md;
      padding-right: $gap_md;
    }

    &.ph-extra-lg {
      padding-left: $gap_lg;
      padding-right: $gap_lg;
    }

    &.ph-huge {
      padding-left: $gap_extra-lg;
      padding-right: $gap_extra-lg;
    }

    &.ph-biggest {
      padding-left: $gap_huge;
      padding-right: $gap_huge;
    }

    &.pv-tiny {
      padding-top: $gap_tiny;
      padding-bottom: $gap_tiny;
    }

    &.pv-extra-sm {
      padding-top: $gap_tiny;
      padding-bottom: $gap_tiny;
    }

    &.pv-sm {
      padding-top: $gap_extra-sm;
      padding-bottom: $gap_extra-sm;
    }

    &.pv-md {
      padding-top: $gap_sm;
      padding-bottom: $gap_sm;
    }

    &.pv-lg {
      padding-top: $gap_md;
      padding-bottom: $gap_md;
    }

    &.pv-extra-lg {
      padding-top: $gap_lg;
      padding-bottom: $gap_lg;
    }

    &.pv-huge {
      padding-top: $gap_extra-lg;
      padding-bottom: $gap_extra-lg;
    }

    &.pv-biggest {
      padding-top: $gap_huge;
      padding-bottom: $gap_huge;
    }
  }
}

@include until-tablet {
  .row {
    &.ph-tiny {
      padding-left: $gap_tiny;
      padding-right: $gap_tiny;
    }

    &.ph-extra-sm {
      padding-left: $gap_tiny;
      padding-right: $gap_tiny;
    }

    &.ph-sm {
      padding-left: $gap_tiny;
      padding-right: $gap_tiny;
    }

    &.ph-md {
      padding: $gap_extra-sm
    }

    &.ph-lg {
      padding-left: $gap_sm;
      padding-right: $gap_sm;
    }

    &.ph-extra-lg {
      padding-left: $gap_md;
      padding-right: $gap_md;
    }

    &.ph-huge {
      padding-left: $gap_lg;
      padding-right: $gap_lg;
    }

    &.ph-biggest {
      padding-left: $gap_extra-lg;
      padding-right: $gap_extra-lg;
    }

    &.pv-tiny {
      padding-top: $gap_tiny;
      padding-bottom: $gap_tiny;
    }

    &.pv-extra-sm {
      padding-top: $gap_tiny;
      padding-bottom: $gap_tiny;
    }

    &.pv-sm {
      padding-top: $gap_tiny;
      padding-bottom: $gap_tiny;
    }

    &.pv-md {
      padding: $gap_extra-sm
    }

    &.pv-lg {
      padding-top: $gap_sm;
      padding-bottom: $gap_sm;
    }

    &.pv-extra-lg {
      padding-top: $gap_md;
      padding-bottom: $gap_md;
    }

    &.pv-huge {
      padding-top: $gap_lg;
      padding-bottom: $gap_lg;
    }

    &.pv-biggest {
      padding-top: $gap_extra-lg;
      padding-bottom: $gap_extra-lg;
    }
  }
}
