.progress-bar {
  position: relative;
  box-sizing: border-box;

  &.circle {
    width: 4.5rem;
    min-height: 4.5rem;
    background-color: transparent !important;

    svg {
      position: absolute;
      top: 0;
      left: 0;
    }

    .slider-item__icon {
      margin-top: 0.7rem;
    }
  }

  &.line {
    height: 0.5rem;
    width: 100%;
    background-color: var(--border);
    border-radius: 0.5rem;

    &.multiple {
      display: flex;
      gap: 4px;
      background-color: var(--scheme_color);

      .progress-bar__filler {
        &:first-child {
          border-top-left-radius: 0.5rem;
          border-bottom-left-radius: 0.5rem;
        }
        &:last-child {
          border-top-right-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
        }
      }
    }
  }
}

.progress-bar__filler {
  height: 100%;
  background-color: var(--scheme_color);
  border-radius: inherit;
  box-sizing: border-box;
}

.progress-bar__content {
  width: 2.7rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.circle-background,
.circle-progress {
  fill: none;
}

.circle-background {
  stroke: var(--border);
}

.circle-progress {
  stroke: var(--scheme_color);
  stroke-linecap: round;
  stroke-linejoin: round;
}
