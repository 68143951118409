@import '../../styles/variables.module';
@import 'src/styles/mixins';

.header {
  flex-shrink: 0;
  height: $header-height;

  .user-icon {
    font-size: 0.9rem;
    width: 2.5rem;
    height: 2.5rem;
    text-transform: uppercase;
  }
}

.header__name {
  color: var(--text-title-light);
  @include threeDots;

  strong {
    text-transform: capitalize;
  }
}

.header__icon {
  margin-left: 0.9rem;
}

.header__profile {
  display: grid;
  grid-template-columns: minmax(2rem, 20fr) auto;
  color: var(--black);
  align-items: center;

  &.with-hover {
    cursor: pointer;
    .header__name:hover {
      color: var(--role-color);
    }
  }
}

.header__notification {
  position: relative;
}

.header__notification-indicator {
  display: flex;
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
  background-color: var(--red-light);
  border: 0.1rem solid var(--white);
  position: absolute;
  right: -0.25rem;
  bottom: 0.7rem;
}

.header__menu-icon {
  cursor: pointer;

  i {
    font-size: 0.9rem;
    color: var(--role-color);
  }

  &:hover {
    i {
      color: var(--role-color-light);
    }
  }
}

.server-status-box {
  position: fixed;
  top: 0;
  left: 0;
  font-size: 0.6rem;
  padding: 0.1rem 0.2rem;
  background-color: var(--text-error);
  color: var(--white);
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

@include until-tablet {
  .header {
    padding-top: $gap_extra-sm;
    padding-bottom: $gap_extra-sm;
    height: unset;
  }

  .header-title {
    font-size: 1rem;
  }
  .header__profile {
    display: block;
  }
  .header__name {
    display: none;
  }
}
