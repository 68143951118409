@import "src/styles/mixins";
@import "src/styles/variables.module";

.select-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0; // Fix list height overflow.

  // Fix list height overflow.
  .scrollbars-wrapper {
    display: flex;
    flex-direction: column;
  }
  // ----------

  li {
    position: relative;
  }
}

// To the best of my memory, it fixes list's height overflow.
.select-list__scrollbars {
  display: flex;
  flex-direction: column;
}
// ----------

.select-list_input {
  flex-shrink: 0;
  margin-bottom: $gap_md;
}
