@import 'src/styles/mixins';

.slider {
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;

  &:after {
    position: absolute;
    top: -0.3rem;
    left: 0;
    right: 0;
    bottom: -0.3rem;
    content: "";
  }

  &.disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.3;
  }

  &.dragging {
    .slider_progress {
      transition: width 0s;
    }
  }
}

.slider_progress {
  transition: width 0.25s;
}

@include until-desktop {
  .slider {
    min-width: 128px;
  }
}
