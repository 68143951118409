 @import "src/styles/mixins";
@import "src/styles/variables.module";

.row {
  &.g-tiny {
    &.grid {
      grid-gap: $gap_tiny;
    }

    &.flex {
      @include flexGap($gap_tiny);
    }
  }

  &.g-extra-sm {
    &.grid {
      grid-gap: $gap_extra-sm;
    }

    &.flex {
      @include flexGap($gap_extra-sm);
    }
  }

  &.g-sm {
    &.grid {
      grid-gap: $gap_sm;
    }

    &.flex {
      @include flexGap($gap_sm);
    }
  }

  &.g-md {
    &.grid {
      grid-gap: $gap_md;
    }

    &.flex {
      @include flexGap($gap_md);
    }
  }

  &.g-lg {
    &.grid {
      grid-gap: $gap_lg;
    }

    &.flex {
      @include flexGap($gap_lg);
    }
  }

  &.g-extra-lg {
    &.grid {
      grid-gap: $gap_extra-lg;
    }

    &.flex {
      @include flexGap($gap_extra-lg);
    }
  }

  &.g-huge {
    &.grid {
      grid-gap: $gap_huge;
    }

    &.flex {
      @include flexGap($gap_huge);
    }
  }

  &.g-biggest {
    &.grid {
      grid-gap: $gap_biggest;
    }

    &.flex {
      @include flexGap($gap_biggest);
    }
  }
}

@include until-desktop-lg {
  .row {
    &.g-tiny {
      &.grid {
        grid-gap: $gap_tiny;
      }

      &.flex {
        @include flexGap($gap_tiny);
      }
    }

    &.g-extra-sm {
      &.grid {
        grid-gap: $gap_tiny;
      }

      &.flex {
        @include flexGap($gap_tiny);
      }
    }

    &.g-sm {
      &.grid {
        grid-gap: $gap_extra-sm;
      }

      &.flex {
        @include flexGap($gap_extra-sm);
      }
    }

    &.g-md {
      &.grid {
        grid-gap: $gap_sm;
      }

      &.flex {
        @include flexGap($gap_sm);
      }
    }

    &.g-lg {
      &.grid {
        grid-gap: $gap_md;
      }

      &.flex {
        @include flexGap($gap_md);
      }
    }

    &.g-extra-lg {
      &.grid {
        grid-gap: $gap_lg;
      }

      &.flex {
        @include flexGap($gap_lg);
      }
    }

    &.g-huge {
      &.grid {
        grid-gap: $gap_extra-lg;
      }

      &.flex {
        @include flexGap($gap_extra-lg);
      }
    }

    &.g-biggest {
      &.grid {
        grid-gap: $gap_huge;
      }

      &.flex {
        @include flexGap($gap_huge);
      }
    }
  }
}

@include until-tablet {
  .row {
    &.g-tiny {
      &.grid {
        grid-gap: $gap_tiny;
      }

      &.flex {
        @include flexGap($gap_tiny);
      }
    }

    &.g-extra-sm {
      &.grid {
        grid-gap: $gap_tiny;
      }

      &.flex {
        @include flexGap($gap_tiny);
      }
    }

    &.g-sm {
      &.grid {
        grid-gap: $gap_tiny;
      }

      &.flex {
        @include flexGap($gap_tiny);
      }
    }

    &.g-md {
      grid-gap: $gap_extra-sm
    }

    &.g-lg {
      &.grid {
        grid-gap: $gap_sm;
      }

      &.flex {
        @include flexGap($gap_sm);
      }
    }

    &.g-extra-lg {
      &.grid {
        grid-gap: $gap_md;
      }

      &.flex {
        @include flexGap($gap_md);
      }
    }

    &.g-huge {
      &.grid {
        grid-gap: $gap_lg;
      }

      &.flex {
        @include flexGap($gap_lg);
      }
    }

    &.g-biggest {
      &.grid {
        grid-gap: $gap_extra-lg;
      }

      &.flex {
        @include flexGap($gap_extra-lg);
      }
    }
  }
}
