@import "src/styles/variables.module";
@import "src/styles/layers";

$transition-duration: 0.25s;

.popup {
  z-index: $popup-z-index;
  position: fixed;

  &.open {
    .popup__inner, .popup__backdrop {
      opacity: 1;
      visibility: visible;
    }
  }
}

.popup__inner {
  z-index: 1;
  position: fixed;

  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: $gap_lg;
  border-radius: 0.66rem;

  background-color: var(--white);
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.12);
}

.popup__backdrop {
  z-index: 0;
}

.popup__inner, .popup__backdrop {
  opacity: 0;
  visibility: hidden;

  transition: opacity #{$transition-duration}, visibility #{$transition-duration};
}
