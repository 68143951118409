@import "src/styles/variables.module";

@mixin setColorsForType($type, $color-bg, $color-title, $color-body, $color-btn-close) {
  &--#{$type} {
    @if($color-bg) {
      background-color: $color-bg;
    }

    @if($color-title) {
      .toaster-enhanced_text-title {
        color: $color-title;
      }
    }

    @if($color-body) {
      .toaster-enhanced_text-body {
        color: $color-body;
      }
    }

    @if($color-btn-close) {
      .toast__close-btn {
        color: $color-btn-close;
      }
    }
  }
}

.Toastify__toast {
  padding: $gap_extra-sm $gap_md;
  border-radius: $border-radius_sm;

  background-color: var(--white);

  @include setColorsForType(success, false, var(--green), false, false);
  @include setColorsForType(error, false, var(--red), false, false);
  @include setColorsForType(info, var(--chat-reminder), var(--white), var(--white), var(--white));
}

.Toastify__toast-body {
  display: grid;
  grid-row-gap: 0.45rem;

  padding-right: 1.2rem; // To don't cover the close button.
}

.Toastify__toast-icon {
  display: none;
}

.toast__close-btn {
  position: absolute;
  top: 0.35rem;
  right: 0.6rem;
  padding: 0.2rem;

  i {
    font-size: 0.7rem;
  }
}
