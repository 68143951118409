.server-popup {
  width: 100%;
}

.server-popup__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  input[type='radio'] {
    position: absolute;
    width: 1px;
    height: 1px;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
  }

  &.is-active {
    .server-popup__text {
      color: var(--role-color);
    }
  }
}

.server-popup__text {
  font-size: 1.1rem;
  color: var(--text-title);
  transition: color 0.3s;

  &:hover {
    color: var(--role-color);
  }
}

.server-popup__icon {
  color: var(--role-color);
}

.server-popup__actions {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}
