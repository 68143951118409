@import "../../styles/variables.module";

.float-window {
  z-index: var(--data-z-index);
  position: fixed;
  right: $gap_md;
  bottom: $gap_md;

  display: flex;
  flex-direction: column;
  width: 460px;
  max-width: 40vw;
  min-height: 320px;
  height: 602px;
  max-height: 94vh;
  border-radius: $border-radius_lg;

  background: var(--white);
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.25);

  [data-draggable] {
    cursor: move;
  }

  &.dragging {
    user-select: none;
  }
}
