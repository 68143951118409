@import "src/styles/mixins";

$hover-border_size: 4px;
$border_size: 1px;

.Resizer {
  z-index: 1;

  box-sizing: border-box;
  opacity: 0.3;

  background: var(--gray);
  background-clip: padding-box;

  transition: border 0.2s ease;
}

.Resizer.horizontal {
  width: 100%;
  height: #{$border_size + $hover-border_size * 2};

  margin: -#{$hover-border_size} 0;
  border-top: #{$hover-border_size} solid rgba(255, 255, 255, 0);
  border-bottom: #{$hover-border_size} solid rgba(255, 255, 255, 0);

  cursor: row-resize;
}

.Resizer.horizontal:hover,
.Resizer.vertical:hover {
  border-color: var(--green);
}

.Resizer.disabled:hover {
  border-color: transparent;
}

.Resizer.vertical {
  width: #{$hover-border_size * 2 + $border_size};

  margin: 0 -#{$hover-border_size};
  border-left: #{$hover-border_size} solid rgba(255, 255, 255, 0);
  border-right: #{$hover-border_size} solid rgba(255, 255, 255, 0);

  cursor: col-resize;
}

.Resizer.disabled {
  cursor: not-allowed;
}
