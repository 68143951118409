.user-icon {
  $size: 3.3rem;
  width: $size;
  height: $size;

  box-sizing: border-box;
  border-radius: 25%;
  color: var(--white);
  font-size: 1.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  white-space: nowrap; // prevent wrap hieroglyphs text, better to show them with overflowed width.

  &.awr {
    background-color: var(--white) !important;

    i {
      height: 3.5rem;

      color: var(--blue-dark);

      font-size: 3.5rem;
      line-height: 1;
    }
  }

  &.all {
    background-color: var(--blue-dark) !important;

    i {
      font-size: 2rem;
      height: 2rem;
    }
  }

  &.broadcast {
    i {
      height: 2.5rem;

      font-size: 2.5rem;
      line-height: 1;
    }
  }

  &.sm {
    $size: 1.8rem;

    width: $size;
    height: $size;
    font-size: 0.9rem;

    &.awr {
      i {
        height: $size;

        color: var(--blue-dark);

        font-size: $size;
        line-height: 1;
      }
    }

    &.all {
      i {
        font-size: 1.3rem;
        height: 1.3rem;
      }
    }
  }

  &.sm {
    $size: 1.8rem;

    width: $size;
    height: $size;
    font-size: 0.9rem;

    i {
      height: $size;

      font-size: $size;
      line-height: 1;
    }
  }
}
