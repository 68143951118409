.btn-char {
  border: none;

  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 0.8rem;

  background: none;
  color: var(--text-title);

  cursor: pointer;

  &:hover {
    color: var(--scheme_color-light);
  }

  &.active {
    color: var(--scheme_color);
  }
}