@use "sass:math";

@import '../../styles/variables.module';
@import "src/styles/mixins";

$transition-duration: 0.25s;

.btn {
  box-sizing: border-box;
  padding: 0.6rem;
  margin: 0; // Ipad, IOS 12 reset.
  border-radius: $border-radius_md;

  font-family: Roboto, sans-serif;
  font-weight: 700;

  outline: none;
  cursor: pointer;

  @include threeDots();

  i {
    display: inline-flex;
  }

  svg {
    fill: currentColor;
  }

  &.default {
    border: none;
    background: linear-gradient(90deg, var(--scheme_color-gradient-from), var(--scheme_color-gradient-to));
    color: var(--white);

    &:hover, &.is-active {
      background: linear-gradient(90deg, var(--scheme_color-gradient-hover-from), var(--scheme_color-gradient-hover-to));
    }

    &[disabled] {
      opacity: 0.55;
    }
  }

  &.opacity {
    border: 1px solid transparent;
    background-color: var(--scheme_color-opacity);
    color: var(--scheme_color);

    transition: border #{$transition-duration};

    &:hover, &.is-active {
      border: 1px solid var(--scheme_color-light);
    }

    &[disabled] {
      opacity: 0.55;
    }
  }

  &.outline {
    border: 1px solid var(--scheme_color);

    background-color: transparent;
    color: var(--scheme_color);

    transition: background-color #{$transition-duration}, color #{$transition-duration};

    &:hover, &.is-active {
      background-color: var(--scheme_color);
      color: var(--white);
    }

    &.is-active {
      &:hover {
        background-color: transparent;
        color: var(--scheme_color);
      }
    }

    &[disabled] {
      border-color: var(--gray-light);
      color: var(--gray-light);
      background-color: transparent;
    }
  }

  &.circle-transparent,
  &.circle {
    $size: 2rem;
    width: $size;
    height: $size;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.1rem;
    border: none;
    border-radius: 50%;

    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
    transition: background-color #{$transition-duration};

    &[disabled] {
      opacity: 0.5;
    }
  }

  &.circle {
    background-color: var(--scheme_color);
    color: var(--white);

    &:hover, &.is-active {
      background-color: var(--scheme_color-dark);
    }
  }

  &.circle-transparent {
    background-color: var(--white);
    color: var(--scheme_color);

    &:hover, &.is-active {
      background-color: var(--scheme_color-dark);
      color: var(--white);
    }
  }

  &.transparent {
    border: none;
    padding-left: 0.1rem;
    padding-right: 0.1rem;
    border-radius: 0 !important;

    background-color: transparent;
    color: var(--scheme_color);

    transition: background-color #{$transition-duration};

    &:hover, &.is-active {
      color: var(--scheme_color-dark);
    }

    &[disabled] {
      color: var(--border);
    }
  }

  &.link {
    border: none;
    padding: 0;
    border-radius: 0;

    background-color: transparent;
    color: var(--scheme_color);

    font-weight: normal;

    &:hover, &.is-active {
      color: var(--scheme_color-light);
    }

    &[disabled] {
      color: var(--gray-light);
    }
  }

  &.bg-white {
    border: none;

    background-color: var(--white);

    color: var(--scheme_color);

    transition: background-color #{$transition-duration}, color math.div($transition-duration, 2);

    &:hover, &.is-active {
      background-color: var(--scheme_color);
      color: var(--white);
    }

    &[disabled] {
      color: var(--gray-light);
    }
  }

  &.disabled,
  &.disabled:hover {
    cursor: default;
    pointer-events: none;
  }

  &.extra-sm {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    border-radius: 0.45rem;

    font-size: 0.8rem;
    font-weight: bold;
  }

  // Experimental feature.
  &.min-width-sm {
    min-width: 120px;
  }
  &.min-width-md {
    min-width: 160px;
  }
  &.min-width-lg {
    min-width: 200px;
  }

  &.sm {
    font-size: 0.8rem;
  }

  &.md {
    font-size: 0.9rem;
  }

  &.lg {
    font-size: 1rem;
  }

  &.biggest {
    font-size: 1.35rem;
  }

  &.with-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      display: block;
      @include threeDots;
    }
  }

  &.br-s-extra-lg {
    border-radius: $border-radius_extra-lg;
  }
}

@include tablet-only {
  .btn {
    &.sm {
      font-size: 0.75rem;
    }

    &.md {
      font-size: 0.8rem;
    }

    &.lg {
      font-size: 0.85rem;
    }

    &.biggest {
      font-size: 1.2rem;
    }
  }
}

@include until-tablet {
  .btn {
    &.sm {
      font-size: 0.7rem;
    }

    &.md {
      font-size: 0.75rem;
    }

    &.lg {
      font-size: 0.75rem;
    }

    &.biggest {
      font-size: 1.1rem;
    }
  }
}
