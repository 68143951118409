@import "src/styles/variables.module";
@import "src/styles/mixins";

.tag {
  display: flex;
  align-items: center;
  max-width: 196px;

  border-radius: $border-radius_md;

  cursor: pointer;

  i {
    font-size: 0.6rem;
  }

  &.extra-sm {
    padding: 0.15rem $gap_extra-sm;
    font-size: 0.7rem;
  }

  &.sm {
    padding: $gap_tiny $gap-sm;

    font-size: 0.8rem;
  }

  &.opacity {
    background-color: var(--scheme_color-opacity);
    color: var(--text-title);
  }

  &.outline {
    border: 1px solid var(--scheme_color);

    background-color: var(--white);
    color: var(--gray);
  }
}

.tag_children {
  @include threeDots();
}

.tag_btn {
  padding: 0.1rem 0.2rem !important;
}
