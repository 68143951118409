@import "src/styles/mixins";

.auth-container {
  width: 100%;
}

@include from-tablet {
  .auth-container {
    &.sm {
      max-width: 474px;
    }

    &.lg {
      max-width: 828px;
    }
  }
}
