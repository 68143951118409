.achievements-grid {
  display: grid;
  grid-template-columns: minmax(80px, auto) minmax(80px, auto) minmax(80px, auto);
  grid-column-gap: 1rem;
  grid-row-gap: 2rem;
  overflow: hidden;
  margin-bottom: 1rem;
  align-items: flex-start;

  .achievement-item {
    margin-right: 0;
  }
}

.achievement-info {
  .achievement-item {
    width: 100%;
    margin-right: 0;
    flex-direction: row;
    align-items: flex-start;
  }

  .achievement-item__icon {
    min-width: 5.5rem;
    margin-right: 1.1rem;
  }

  .achievement-item__content {
    width: 100%;
  }

  .achievement-item__title {
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 1.2rem;
    line-height: 1.5;
    color: var(--text-title);
    display: block;
    margin-bottom: 0.3rem;
  }

  .achievement-item__subtitle {
    font-size: 0.8rem;
    line-height: 1.2;
    color: var(--gray);
    display: block;
    margin-bottom: 0.9rem;
  }

  .achievement-info__description {
    margin-top: 2.2rem;
    font-size: 0.9rem;
    line-height: 1.5;
    color: var(--gray);
  }
}

.achievement-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 2.8rem;
  cursor: pointer;

  .progress-bar__content {
    width: 2.7rem!important;
  }
}

.achievement-item__icon {
  display: flex;
  img {
    width: 100%;
    height: 100%;
  }
}

.achievement-item__text {
  font-size: 0.9rem;
  line-height: 1.2;
  color: var(--gray);
  display: flex;
  margin-top: 0.5rem;
  text-align: center;
}

.achievement-item__completed {
  position: absolute;
  bottom: 0;
  right: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--green);
  border-radius: 50%;
  width: 0.9rem;
  height: 0.9rem;
  border: 2px solid var(--white);

  i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -35%);
    font-size: 0.55rem;
    color: var(--white);
  }
}
