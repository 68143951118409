@import "src/styles/layers";
@import "src/styles/mixins";

$box-shadow: 0px 3px 10px rgba(39, 48, 57, 0.1);
$transition: 250ms;

.side-panel__backdrop {
  opacity: 0;
}

.side-panel__animation {
  position: fixed;
  top: 0;
  right: 0;

  max-width: 25.6rem;
  width: 100%;
  height: 100%;
  z-index: $size-panel-z-index;

  &.panel-enter-done {
    .side-panel__inner {
      right: 0;
      transition: right $transition;
    }
    .backdrop {
      opacity: 1;
      transition: opacity $transition;
    }
  }

  &.panel-exit {
    .side-panel__inner {
      right: -100%;
      transition: right $transition;
    }
    .backdrop {
      opacity: 0;
      transition: opacity $transition;
    }
  }
}

.side-panel__inner {
  position: relative;
  right: -100%;
  display: flex;
  flex-direction: column;

  background-color: var(--white);
  height: 100%;
  z-index: $size-panel-z-index;
  box-shadow: $box-shadow;
  padding: 1.8rem 1.3rem 2.2rem 1.3rem;
  box-sizing: border-box;
}

.side-panel__inner-wrap {
  display: grid;
  grid-row-gap: $gap_extra-lg;
  grid-template-rows: auto 1fr auto;
  height: 100%;
}

.side-panel__title {
  padding-bottom: 0.9rem;
}

.side-panel__list {
  display: flex;
  flex-direction: column;
  height: 100%;

  &.locations {
    .list {
      display: flex;
      flex-direction: column;
    }
  }

  .list {
    height: 100%;
    margin-bottom: 1.1rem;
  }
}

.side-panel__btn {
  flex: none;
}

.side-panel__actions {
  button {
    width: 11.1rem;
  }
}

.mb {
  margin-bottom: 1rem;
}

@include until-mobile-lg {
  .side-panel__inner {
    padding: 1.3rem;
  }
}
