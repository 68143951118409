@import "../../../styles/variables.module";

.message {
  position: relative;

  overflow: hidden; // Prevent audio player width overflow.

  box-shadow: 0 2px 6px rgba(39, 48, 57, 0.1);

  &.in {
    border-bottom-left-radius: 0;
  }

  &.out {
    border-bottom-right-radius: 0;
  }

  .icon-sent,
  .icon-read {
    font-size: 0.5rem;
  }

  .icon-error {
    font-size: 0.7rem;
  }
}

$message_gap-vertical: 0.35rem;

.message {
  padding: $message_gap-vertical $gap_sm $gap_sm;
}

.message_replied {
  margin-left: $gap_lg;
  margin-bottom: $message_gap-vertical;

  cursor: pointer;
}

.msg_quote {
  position: absolute;
  top: 0.5rem;
  left: 0.4rem;

  font-size: 0.75rem;
  color: var(--gray);
  line-height: 1;
}

.msg_divider {
  margin-bottom: $gap_extra-sm;
}

.message_text {
  font-size: 0.9rem;

  word-break: break-word;

  color: var(--text-body);
}

.message_meta {
  position: relative;
  top: 3px;
  margin-left: $gap_sm;
  height: 1rem;
  float: right;
}

.msg-item_tooltip-review {
  display: inline-block;
}

.message_btn-review {
  vertical-align: middle;

  display: inline-flex !important;
  padding: 0 !important;
}

.message_text-review {
  padding-left: 0.15rem;
  padding-right: $gap_tiny;
}
