@import 'src/styles/mixins';

$box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);

.templates_actions {
  @include flexGapHorizontal($gap_md);
}

.template-item {
  position: relative; // For 'User template' icon.
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 5rem;
  height: 100%;

  &.card {
    box-shadow: $box-shadow;
  }
}

.template-item__inner {
  position: relative; // For 'Attach/Detach' button.
  overflow: hidden; // To crop content(e.g. image) borders.
}

.template-item__slot-absolute {
  z-index: 2;
  position: absolute;
  bottom: 0;
  right: 0;

  max-width: 100%;
}

.template-slider-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  padding: 0.6rem 0;

  .scroll-content {
    display: flex;

    &.slider-view {
      padding: 0;

      & > * {
        margin-right: $gap_lg;
      }
    }

    &:not(.slider-view) {
      flex-wrap: wrap;

      padding-left: $gap_sm;
      padding-right: $gap_sm;

      & > * {
        margin-right: $gap_sm;
        margin-top: $gap_lg;
      }
    }
  }
}

.text-template {
  box-sizing: border-box;
  width: 100%;
  max-width: 12.5rem;
  padding-top: $gap_md;
  padding-left: $gap_md;
  border-radius: 0.4rem;

  .template-item__inner {
    width: 11.6rem;
    padding-right: 0.9rem;
    min-height: 6.7rem;
    cursor: pointer;
  }

  .template-item__title {
    cursor: pointer;
  }

  .template-item__user {
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--green);
    width: 1.1rem;
    height: 1.1rem;
    border-top-right-radius: 0.4rem;
    border-bottom-left-radius: 0.4rem;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 0.7rem;
      display: flex;
      color: var(--white);
    }
  }
}

.video-template {
  .template-item__inner {
    flex-grow: 1;
    display: block;
    border-radius: 0.4rem;
    cursor: pointer;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      display: block;
      background-color: rgba(0,0,0, 0.3);
      top: 0;
      left: 0;
      pointer-events: none;
    }

    &:hover {
      .template-item__play {
        transform: translate(-50%, -50%) scale(1.2);
      }
    }
  }

  .template-item__play {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--white-40);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    transition: transform 0.3s;

    i {
      display: flex;
      color: var(--white);
      font-size: 0.9rem;
    }
  }
}

.image-template {
  .template-item__inner {
    border-radius: 0.4rem;
  }
}

.video-template,
.image-template {
  a,
  img,
  .template-item__inner {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
}

.more-less-btn {
  .is-less {
    transform: rotate(90deg);
    padding-top: 0.5rem;
    margin-left: 0.3rem;
    padding-bottom: 0.2rem;
  }
}

.button-add {
  border-radius: 0.5rem;

  .plus-icon {
    background-color: var(--green);
  }

  &.sm {
    .plus-icon {
      width: 1.4rem;
      height: 1.4rem;
    }
  }

  &.lg {
    .plus-icon {
      width: 2rem;
      height: 2rem;

      i {
        font-size: 0.7rem;
      }
    }
  }
}

.highlighted {
  background-color: var(--highlighted);
}
