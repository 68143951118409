.share-story__title {
  font-size: 0.9rem;
  line-height: 1.5;
  color: var(--gray);
  display: block;
}

.share-story__field {
  position: relative;

  textarea {
    min-height: 259px;
  }
}

.share-story__actions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  margin-top: 0.5rem;
}
