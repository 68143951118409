@import "../../styles/mixins";

@mixin compactStyles {
  .message {
    max-width: 100%;
  }
}

.root {
  &.compact {
    @include compactStyles;
  }
}

.message {
  line-height: 1;
  margin-top: 0.7rem;
  grid-template-columns: 1fr auto !important;

  &:first-of-type {
    margin-top: 0;
  }

  &.in {
    margin-right: auto;
  }

  &.out {
    margin-left: auto;

    // Moves actions block to before the message for outgoing messages.
    .actions {
      grid-column: 1;
      grid-row: 1;
    }
  }
}

.actions {
  margin-top: $gap_tiny;
  align-items: flex-start;

  :global(.btn) {
    padding-top: 0;
    padding-bottom: 0;
  }

  @include flexGapHorizontal($gap_tiny);

  .action-icon {
    font-size: 0.85rem;
  }
}

@include until-desktop {
  @include compactStyles;
}

@include from-desktop {
  .message {
    max-width: 50%;
  }
}