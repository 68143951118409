@import "src/styles/mixins";
@import "src/styles/variables.module";

.chat-input {
  flex-shrink: 0; // Fix shrinking on Ipad.
  max-height: 50%;
}

.chat-input_attachments {
  height: 4.45rem;

  @include flexGapHorizontal($gap_sm);
}

.chat-input_attachment-actions {
  flex-shrink: 0;

  display: flex;
  align-items: center;

  @include flexGapHorizontal($gap_sm);
}

.chat-input_attachments-scroll {
  display: flex;

  & > div:first-of-type {
    display: flex;
    width: auto;
    margin-left: auto;
  }
}

.chat-input_attachments-scroll_inner {
  @include flexGapHorizontal($gap_sm);
}

.chat-input_attachment {
  width: 6rem;
  height: 100%;
}
