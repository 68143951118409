.button-stop-record {
  i {
    animation: animationBlink 1.2s infinite;
  }
}

@keyframes animationBlink {
  50% {
    opacity: 0;
  }
}