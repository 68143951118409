@import '../../styles/variables.module';

.paper {
  border-radius: $border-radius_lg;

  background-color: var(--white);

  box-shadow: $box-shadow;
}

