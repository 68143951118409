.btn-bookmark-attach-toggle {
  display: flex;
  align-items: center;
}

.btn-bookmark-attach-toggle_icon-detach {
  color: var(--red-light);

  font-size: 1.05rem;
}
