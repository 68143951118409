@import "src/styles/mixins";

$scrollbars-render-view-z-index: 0;

.scrollbars-wrapper > div:first-of-type {
  width: 100%;
  height: 100%;
}

.scrollbars-wrapper > div:first-of-type {
  /* Another approach to hide native scrollbars. */
  // Compensate negative margins given by the plugin.
  margin-right: 0 !important;
  margin-bottom: 0 !important;

  @include hideScrollbars();
}

.scrollbars-wrapper {
  &.static {
    & > div:first-of-type {
      position: static !important;
    }
  }

  &.horizontal {
    & > div:first-of-type {
      display: flex;
      align-items: flex-start;
    }
  }

  &.flex-column {
    & > div:first-of-type {
      display: flex;
      flex-direction: column;
    }
  }

  &.gap-for-scrollbar {
    & > div:first-of-type {
      box-sizing: border-box;
      padding-right: $gap_md;
    }
  }
}
