.time-picker_input-hours,
.time-picker_input-minutes {
  width: 1.3rem;
}

.time-picker_input-hours {
  flex-grow: 0;
}

.time-picker_separator {
  width: 0.5rem;
  flex-grow: 0;
}
