@import 'src/styles/variables.module';

.from-sign-in__block-forgot-password {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 1rem;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 0.4rem;
}
