$box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);

.courses-grid {
  display: grid;
  grid-template-columns: minmax(200px, auto) minmax(200px, auto);
  grid-column-gap: 1rem;
  grid-row-gap: 2rem;
  overflow: hidden;
  margin-bottom: 1rem;

  .courses-item__icon {
    width: 100%;
  }
}

.courses-info {
  .courses-item {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    box-sizing: border-box;
    padding: 0.1rem;
    cursor: default;
  }

  .courses-item__icon {
    width: 100%;
    height: 8.2rem;
  }

  .courses-item__description {
    position: relative;
    width: 100%;
    border-radius: 0.4rem;
    box-shadow: $box-shadow;
    padding: 0.6rem 1.2rem 1.1rem;
    box-sizing: border-box;
    background-color: var(--white);
    margin-top: -0.5rem;
  }

  .flex-row {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0.8rem 0;
  }

  .courses-item__text {
    &:first-child {
      margin-right: 1.1rem;
    }
  }
}

.courses-info__description {
  font-size: 0.9rem;
  line-height: 1.5;
  color: var(--text-title);
  margin-bottom: 0.7rem;
  overflow: hidden;
}

.courses-info__lessons {
  margin-top: 1.4rem;
}

.lesson-item {
  background-color: var(--white);
  padding: 0.7rem 0.7rem 0.5rem 0.7rem;
  margin-bottom: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border: 0.1rem solid var(--border);
  border-radius: 0.4rem;
  transition: border 0.3s, box-shadow 0.3s;

  &:hover {
    box-shadow: $box-shadow;
    border: 0.1rem solid transparent;
  }

  button.button {
    min-width: 88px;
    border-radius: 1.5rem;
  }
}

.lesson-item__subtitle {
  font-size: 0.8rem;
  line-height: 1.25;
  color: var(--gray);
}

.lesson-item__title {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 0.9rem;
  line-height: 1.5;
  color: var(--text-title);
}
