@import "src/styles/mixins";

.typography, .h1, .h2, .h3, .h4, .h5, .h6, .subtitle1, .body1, .body2, .body3, .body4 {
  margin-top: 0;
  margin-bottom: 0;

  word-break: break-word; // To wrap long solid words (e.g. long word in the message text of the {ChatItem}).

  &.three-dots {
    @include threeDots();
  }

  &.l {
    text-align: left;
  }

  &.c {
    text-align: center;
  }

  &.r {
    text-align: right;
  }

  &.line-clamp {
    @include lineClamp(var(--clamp), 1);
  }
}

.body4 {
  &.l, &.c, &.r {
    display: block;
  }
}

.h1 {
  font-weight: 400;
  $line-height: 1.25;
  line-height: $line-height;

  &.line-clamp {
    @include lineClamp(var(--clamp), $line-height);
  }
}

.h2 {
  font-weight: 700;
  $line-height: 1.5;
  line-height: $line-height;

  &.line-clamp {
    @include lineClamp(var(--clamp), $line-height);
  }
}

.h3, .subtitle1 {
  font-weight: bold;
  $line-height: 1.5;
  line-height: $line-height;

  &.line-clamp {
    @include lineClamp(var(--clamp), $line-height);
  }
}

.h4 {
  font-weight: 700;
  $line-height: 1.35;
  line-height: $line-height;

  &.line-clamp {
    @include lineClamp(var(--clamp), $line-height);
  }
}

.h5 {
  font-weight: 700;
  $line-height: 1.4;
  line-height: $line-height;

  &.line-clamp {
    @include lineClamp(var(--clamp), $line-height);
  }
}

.h6 {
  font-weight: 700;
  $line-height: 1.35;
  line-height: $line-height;

  &.line-clamp {
    @include lineClamp(var(--clamp), $line-height);
  }
}

.h1, .h2, .h3, .h4, .h5, .h6, .subtitle1 {
  color: var(--text-title);
}

.body1, .body2, .body3, .body4 {
  font-weight: 500;

  color: var(--text-body);
}

.subtitle1 {
  font-weight: normal;
}

.body1 {
  $line-height: 1.55;
  line-height: $line-height;

  &.line-clamp {
    @include lineClamp(var(--clamp), $line-height);
  }
}

.body2, .body3, .body4 {
  $line-height: 1.5;
  line-height: $line-height;

  &.line-clamp {
    @include lineClamp(var(--clamp), $line-height);
  }
}

@include from-desktop {
  .h1 {
    font-size: 2.65rem;
  }

  .h2 {
    &.poppins {
      font-size: 1.65rem;
    }

    &.roboto {
      font-size: 1.77rem;
    }
  }

  .h3, .subtitle1 {
    &.poppins {
      font-size: 1.4rem;
    }

    &.roboto {
      font-size: 1.35rem;
    }
  }

  .h4 {
    &.poppins {
      font-size: 0.9rem;
    }

    &.roboto {
      font-size: 1rem;
    }
  }

  .h5 {
    font-size: 0.8rem;
  }

  .h6 {
    font-size: 0.7rem;
  }

  .body1 {
    font-size: 1rem;
  }

  .body2 {
    font-size: 0.9rem;
  }

  .body3 {
    font-size: 0.8rem;
  }

  .body4 {
    font-size: 0.7rem;
  }
}

@include tablet-only {
  .h1 {
    font-size: 2.4rem;
  }

  .h2 {
    font-size: 1.45rem;
  }

  .h3, .subtitle1 {
    font-size: 1.2rem;
  }

  .h4 {
    font-size: 0.9rem;
  }

  .h5 {
    font-size: 0.75rem;
  }

  .h6 {
    font-size: 0.65rem;
  }

  .body1 {
    font-size: 0.9rem;
  }

  .body2 {
    font-size: 0.8rem;
  }

  .body3 {
    font-size: 0.75rem;
  }

  .body4 {
    font-size: 0.65rem;
  }
}

@include until-tablet {
  .h1 {
    font-size: 2.15rem;
  }

  .h2 {
    font-size: 1.25rem;
  }

  .h3, .subtitle1 {
    font-size: 1.05rem;
  }

  .h4 {
    font-size: 0.8rem;
  }

  .h5 {
    font-size: 0.7rem;
  }

  .h6 {
    font-size: 0.6rem;
  }

  .body1 {
    font-size: 0.8rem;
  }

  .body2 {
    font-size: 0.75rem;
  }

  .body3 {
    font-size: 0.7rem;
  }

  .body4 {
    font-size: 0.6rem;
  }
}
